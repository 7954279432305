.MainPage {
    width: 350px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .Greeting {
        text-align: center;
        width: 350px;
        height: 80px;
    }

    .Contacts {
        display: flex;
        justify-content: space-evenly;
        margin-top: 30px;

        a {
            color: rgb(73, 73, 73);
            text-decoration: none;

            &:visited {
                color: rgb(73, 73, 73);
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .Lang {
        position: absolute;
        top: 5%;
        right: 15%;
        font-size: 16px;

        & > * {
            display: inline-block;

            width: 25px;
            height: 25px;

            text-align: center;
            line-height: 25px;

            &:hover {
                cursor: pointer;
                transition: none;
            }
            transition: background-color 0.2s ease-in;
        }

        #en {
            &:hover {
                background-color: rgb(32, 76, 196);
            }

            &:active {
                background-color: rgba(32, 76, 196, 0.5);
            }
        }

        #ru {
            &:hover {
                background-color: rgb(201, 61, 68);
            }

            &:active {
                background-color: rgba(201, 61, 68, 0.5);
            }
        }

        #uz {
            &:hover {
                background-color: rgb(43, 211, 80);
            }
            &:active {
                background-color: rgba(43, 211, 80, 0.5);
            }
        }
    }
}
