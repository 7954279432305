@font-face {
    font-family: "SFProText";
    src: local("SFProText"),
     url("./fonts/SFProText-Regular.ttf") format("truetype");
    font-weight: bold;
    }

body {
  margin: 0;
  font-family: "SFProText";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

